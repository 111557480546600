import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {endpoint} from './App';
const VerifyEmail = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the token from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      axios.get(`${endpoint}/verify?token=${token}`)
        .then(response => {
          alert("Email verified successfully!");
          // Optionally, redirect to login or another page
          // navigate('/login');
        })
        .catch(error => {
          alert("Invalid or expired token. Please try again.");
          console.error("Verification error:", error);
        });
    } else {
      alert("No verification token found in the URL.");
    }
  }, [location]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>Please wait while we verify your email...</p>
    </div>
  );
};

export default VerifyEmail;