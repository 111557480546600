import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';
const CommentForm = ({ postId, onAddComment }) => {
  const [content, setContent] = useState('');
  const { user } = useAuth();
  const textareaRef = useRef(null);

  useEffect(() => {
    handleTextareaResize();
  }, [content]);

  const handleTextareaResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const comment = {
      postId,
      content
    };
    onAddComment(comment);
    setContent('');
  };
  const avatarSvg = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>';


  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <Link to={`/profile/${user.username}`}>
        <img src={user.avatarUrl} className="comment-avatar" />
      </Link>
      <textarea
        name="content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onInput={handleTextareaResize}
        ref={textareaRef}
        placeholder="Share your solutions/products here..."
        required
        style={{
          minHeight: '41px',
          maxHeight: '200px', // Set a maximum height if desired
          overflow: 'hidden',
          boxSizing: 'border-box'
        }}
      />
      <button type="submit">Add</button>
    </form>
  );
};

export default CommentForm;