import { Link } from 'react-router-dom';
import './PostDetailHeader.css';
import React, { useState } from 'react';
import { useAuth } from './AuthContext';


const PostDetailHeader = ({ post, isEditing, setIsEditing, setEditPost }) => {
  const { user, setUser } = useAuth();

  return (
    <div className="post-detail-header">
      <div className='first-block'>
        <Link to="/" className="back-link">Back</Link>
        <h1 className="post-title">{post.title}</h1>
      </div>
      <div>
        <>
        {user?.ownedPosts && user.ownedPosts.includes(post.id) &&
              <button onClick={() => {setEditPost(post);setIsEditing(true)}} className="post-detail-edit-button">Edit</button>
        }
        </>
      </div>
      
    </div>
  );
};

export default PostDetailHeader;