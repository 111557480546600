import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './App.css';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import GlobalHeader from './GlobalHeader';
import Home from './Home'
import PostDetail from './PostDetail'
import { AuthProvider } from './AuthContext';
import ProfilePage from './ProfilePage';
import VerifyEmail from './EmailVerificationPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import PasswordReset from './PasswordReset';

// export const endpoint = 'https://www.demantrics.io/api';
export const endpoint = 'http://localhost:8080/api';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
        <GlobalHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/password-reset" element={<PasswordReset />}/>
            <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
            <Route path="/post/:id" element={<PostDetail />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/profile/:username" element={<ProfilePage />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>

  );
}

export default App;
