// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e9e9e9;
  }
  
  .register-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 30vw;
    padding: 5vh;
    text-align: center;
    min-height: 50vh; /* Adjust height as needed */
    margin-bottom: 20vh;
  }
  
  .register-card h2 {
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #888;
    font-weight: 400;
    text-align: left;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .register-button {
    background-color: #0073e6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .register-button:hover {
    background-color: #005bb5;
  }`, "",{"version":3,"sources":["webpack://./src/RegisterPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,yCAAyC;IACzC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB,EAAE,4BAA4B;IAC9C,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["\n.register-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #e9e9e9;\n  }\n  \n  .register-card {\n    background-color: white;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    width: 30vw;\n    padding: 5vh;\n    text-align: center;\n    min-height: 50vh; /* Adjust height as needed */\n    margin-bottom: 20vh;\n  }\n  \n  .register-card h2 {\n    margin-bottom: 20px;\n    font-weight: 500;\n  }\n  \n  .input-group {\n    margin-bottom: 20px;\n  }\n  \n  .input-group label {\n    display: block;\n    margin-bottom: 5px;\n    color: #888;\n    font-weight: 400;\n    text-align: left;\n  }\n  \n  .input-group input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    font-size: 16px;\n  }\n  \n  .error {\n    color: red;\n    font-size: 14px;\n    margin-bottom: 20px;\n  }\n  \n  .register-button {\n    background-color: #0073e6;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n    width: 100%;\n  }\n  \n  .register-button:hover {\n    background-color: #005bb5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
