import React, {useState, useEffect} from 'react';
import { Link, useNavigate} from 'react-router-dom';
import SortDropdown from './SortDropdown'; // Adjust the path if necessary
import { useAuth } from './AuthContext';
import axios from 'axios';
import _ from 'lodash';
import { endpoint } from './App';


const PostList = ({
  posts,
  setPosts,
  sortOption,
  setSortOption,
  setShowForm,
  setPostIdForDeletion,
  setDeleteConfirmation,
  setIsEditing,
  setEditPost,
  showForm,
  isEditing,
  editPost,
  newPost,
  handleInputChange,
  handleSubmit,
  showDeleteConfirmation,
  deletePost,
  toggleVote,
  industries,
  postIdForDeletion,
  isPostListLoading,
  setIsPostListLoading
}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() returns 0-11
        const day = date.getDate();

        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [loadingMorePosts, setLoadingMorePosts] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const industriesWithoutAll = ["", "Other", "Medical", "Technology", "Education", "Finance", "Retail", "Hospitality"];
    const { user, loading, setLoading } = useAuth();
    const handleClick = (e) => {
        e.preventDefault();
        setShowForm(true)
        // Handle the action you want when the textarea is "clicked" in disabled state
        // You can open your form here
      };
    const [errors, setErrors] = useState({ title: false, content: false, industryType: false });

    const fetchMorePosts = async (page = 0, industryType = 'All', sortOption = 'None') => {
        if (loadingMorePosts) return; // Prevent multiple fetches
        setLoadingMorePosts(true);
        try {
          const response = await axios.get(`${endpoint}/posts/industryType/${industryType}?sortOption=${sortOption}&page=${page}&size=5`, {
          });
          const data = response.data; // Access the data directly from the response      
          setPosts(prevPosts => [...prevPosts, ...data.content]); // Append new posts
          setCurrentPage(page); // Update current page
          setHasMore(data.content.length > 0); // Check if more data is available
        } catch (error) {
          console.error('Error fetching posts:', error);
        } finally {
          setLoadingMorePosts(false);
        }
      };

    useEffect(() => {
        const handleScroll = _.debounce(() => {
            const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
            const scrollableHeight = document.documentElement.offsetHeight;
          
            if (scrollPosition >= scrollableHeight && hasMore) {
              fetchMorePosts(currentPage + 1, localStorage.getItem('selectedIndustry'), sortOption);
            }
          }, 300); // Debounce to ensure the event triggers once user has finished scrolling
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPage, sortOption]);
    

    const isPostFormValid = () => {
        let formErrors = { title: false, content: false, industryType: false };
        let isValid = true;
        
        if (isEditing ? !editPost.title : !newPost.title) {
            formErrors.title = true;
            isValid = false;
        }
        
        if (isEditing ? !editPost.content : !newPost.content) {
            formErrors.content = true;
            isValid = false;
        }
        
        if (isEditing ? !editPost.industryType : !newPost.industryType) {
            formErrors.industryType = true;
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    }

    const handleSubmitWithValidation = (e) => {
        e.preventDefault();
        if (isPostFormValid()) {
            handleSubmit(e)
        } else {
            alert('Please fill in all fields')
        }
    }

    return (
        <div className={`post-list-${loading ? 'greyed-out' : 'normal'}`}>
            <div className="post-list-header">
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <>
                {user ? (
                    <div className='input-toggler'>
                    <div className='header-avatar'>
                        <img src={user.avatarUrl} className="comment-avatar" />
                    </div>
                    <div className='post-text-area-wrapper' onClick={handleClick}>
                        <p className='problem-prompt'>"Share your problems here..."</p>
                    </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </>
                <SortDropdown sortOption={sortOption} setSortOption={setSortOption} />
                </div>
                    {posts.map((post) => (
                        <div key={post.id} className="post">
                            <div className='post-headline'>
                            <Link to={`/profile/${post.ownerUsername}`}>
                                <img src={post.ownerAvatarUrl} className="comment-avatar" alt="User Avatar" />
                            </Link>
                                <h3 className='list-post-username'>{post.ownerUsername}</h3>
                                <p className='list-post-misc'>Needs: {post.demand}</p>
                                <p className='list-post-misc'>|</p>
                                <p className='list-post-misc'>{post.views} views</p>
                                <p className='list-post-misc'>|</p>
                                <p className='list-post-misc'>
                                    {post.lastEditedDate ? `Last Edited: ${formatDate(post.lastEditedDate)}` : `${formatDate(post.createdDate)}`}
                                </p>
                            </div>
                            <h3 className='list-post-title'><Link to={`/post/${post.id}`}>{post.title}</Link></h3>
                            <p>{post.content}</p>
                            <div className='post-button-row'>
                                {user?.ownedPosts && user.ownedPosts.includes(post.id) && (
                                    <>
                                        <div className = "post-delete-button-div">
                                        <button className = "post-delete-button" onClick={() => {
                                        setPostIdForDeletion(post.id);
                                        setDeleteConfirmation(true);
                                        }}>Delete</button>
                                        </div>
                                        <div className = "post-edit-button-div">
                                        <button className = "post-edit-button" onClick={() => {
                                        setIsEditing(true);
                                        setEditPost(post);
                                        }}>Edit</button>
                                        </div>
                                    </>
                                )}
                                <>
                                    {user && (
                                        <div className={`post-vote-button-div-${user.votedPosts.includes(post.id) ? 'voted' : 'not-voted'}`}>
                                            <button
                                                onClick={() => {
                                                user.votedPosts.push(post.id);
                                                toggleVote(post.id, user.votedPosts.includes(post.id));
                                                }}
                                            >
                                                +1
                                            </button>
                                        </div>
                                    )}
                                    {!user && 
                                        <div className = {`post-vote-button-div-not-voted`}>
                                            <button
                                                onClick={() => {
                                                navigate('/login');
                                                }}
                                                >
                                                +1
                                            </button>
                                    </div>}
                                    </>
                            </div>
                        </div>
                    ))}
                    {(showForm || isEditing) && (
                        <div className="post-form">
                            <div className='post-form-inputs'>
                            <form onSubmit={handleSubmitWithValidation}>
                                <div className='title-row'>
                                    <div className='title-industry'>
                                    <img src={user.avatarUrl} className="post-form-avatar" />
                                        <div>
                                            <input 
                                                type="text" 
                                                name="title" 
                                                className="post-form-title" 
                                                value={isEditing ? editPost.title : newPost.title} 
                                                onChange={handleInputChange} 
                                                placeholder="Enter title here..." 
                                                />
                                            </div>
                                        <div>
                                            <select name='industryType' className="industryType" value={isEditing ? editPost.industryType : newPost.industryType} onChange={handleInputChange}>
                                                {industriesWithoutAll.map((industry) => (
                                                <option key={industry} value={industry}>{industry}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <textarea name="content"
                                    className='post-form-content'
                                    value={isEditing ? editPost.content : newPost.content}
                                    onChange={handleInputChange} 
                                    placeholder="Share your problems here..." ></textarea>
                                </div>
                                <div className='post-form-button-row'>
                                    <button type="cancel" onClick={() => {
                                        setShowForm(false);
                                        setIsEditing(false);
                                    }}>Cancel</button>
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    )}
                    {showDeleteConfirmation && (
                        <>
                        <div className="modal-overlay"></div>
                        <div className="delete-confirmation">
                            <h2>Are you sure you want to delete this post?</h2>
                            <div className='delete-button-row'>
                                <button className='delete-button'type="button" onClick={() => {
                                    deletePost(postIdForDeletion);
                                    setDeleteConfirmation(false);
                                }}>Delete</button>
                                <button className='delete-cancel-button' type="button" onClick={() => setDeleteConfirmation(false)}>Close</button>
                            </div>
                        </div>
                        </>
                    )}
        </div>
    );
};

export default PostList;