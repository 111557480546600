import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { Link, useParams } from 'react-router-dom';
import './ProfilePage.css'; // Make sure to create this CSS file
import { endpoint } from './App';
import Modal from 'react-modal';
import DraggableImage from './DraggableImage';

Modal.setAppElement('#root');

const ProfilePage = () => {
    const [imagePos, setImagePos] = useState({ x: 0, y: 0 });
    const [initialImagePos, setInitialImagePos] = useState({ x: 0, y: 0 });
    const { username } = useParams();
    const [profileOwner, setProfileOwner] = useState({});
    const [posts, setPosts] = useState([]);
    const { loading, setLoading } = useAuth();
    const { user, setUser } = useAuth();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const avatarSvg = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>';
    const [avatarRefresh, setAvatarRefresh] = useState(false);
    const [saveImageLoading, setSaveImageLoading] = useState(false);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        fetchUserPosts();
    }, [avatarRefresh]);

    const imageInputRef = useRef(null);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const handleAvatarClick = () => {
        if (user && imagePreview === user.username) {
            setImagePreview(user.avatarUrl);
        } else {
            setImagePreview(profileOwner.avatarUrl);
        }
        setModalIsOpen(true);
    };

    const fetchUserPosts = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            const response = await axios.get(`${endpoint}/posts/${username}`, { headers });
            setProfileOwner(response.data.user);
            setPosts(response.data.userPosts);
        } catch (error) {
            console.error("Error fetching user posts:", error);
        }
        setLoading(false);
    };

    const handleSave = async () => {
        if (!imageFile) {
            // If no image is selected, do nothing
            return;
        }
        const formData = new FormData();
        formData.append('imageFile', imageFile); // 'avatar' is the key your backend will look for
        try {
            setSaveImageLoading(true);
            const token = localStorage.getItem('token'); // Assuming you're using token-based authentication
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            const response = await axios.post(`${endpoint}/user/saveProfileImage`, formData, {
                headers: {
                    ...headers,
                    'Content-Type': 'multipart/form-data', // Ensure the correct content type
                },
            });
            setUser(prevUser => ({
                ...prevUser,
                avatarUrl: response.data.avatarUrl // Assuming the server returns the new URL
            }));
            setImagePreview(response.data.avatarUrl);
            
        } catch (error) {
            console.error("Error uploading image:", error);
        } finally {
            setSaveImageLoading(false);
            setModalIsOpen(false);
            window.location.reload();
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    if (loading) {
        return (
            <>
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            </>
        ); // Show the spinner while loading
    }

    return (
        <div className="profile-page-container">
            <div className="profile-page">
                <div className="main-details-panel">
                    <div className='avatar-username-row'>
                        <div className="profile-avatar" onClick={handleAvatarClick}>
                            <img
                                src={profileOwner.avatarUrl ? profileOwner.avatarUrl : avatarSvg}
                                alt="Profile Avatar"
                            />
                        </div>
                        <div className='profile-title-row'>
                            <h2 className='username'>{profileOwner.username}</h2>
                        </div>
                    </div>
                    <p>Email: {profileOwner.email}</p>
                    <p>{profileOwner.bio}</p>
                </div>
                <div className="my-posts-panel">
                    <h3>Posts</h3>
                    <>
                        {!posts && <div>No post from you yet</div>}
                    </>
                    <ul>
                        {posts.map(post => (
                            <Link key={post.id} to={`/post/${post.id}`} className="post-link">
                                <div className='my-post-row'>
                                    <div className="post-info">
                                        <h4>{post.title}</h4>
                                        <p>{formatDate(post.createdDate)}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>
            <>
                {user && user.username === username ? (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => { setModalIsOpen(false); }}
                        contentLabel="Upload a New Avatar"
                        className="modal-content"
                        overlayClassName="modal-overlay"
                    >
                        <h2>Upload a New Image</h2>
                        <DraggableImage
                            src={imagePreview}
                            imagePos={imagePos}
                            setImagePos={setImagePos}
                            initialImagePos={initialImagePos}
                            setInitialImagePos={setInitialImagePos}
                            onClick={() => imageInputRef.current.click()} /* Trigger file input click on image click */
                        />
                        <input
                            type="file"
                            accept="image/*"
                            ref={imageInputRef}
                            onChange={handleImageChange}
                            style={{ display: 'none' }} /* Hide the file input */
                        />
                        <div className="modal-actions">
                            <button className="profile-image-cancel-button" onClick={() => { setModalIsOpen(false); setImagePreview(user.avatarUrl); }}>
                                <span className="icon">❌</span> {/* Cancel Icon */}
                                Cancel
                            </button>
                            <button
                                className="profile-image-save-button"
                                onClick={() => {
                                    handleSave();
                                    setImageFile(null);
                                }}
                                disabled={saveImageLoading} // Disable the button while saving
                            >
                                <span className="icon">💾</span> {/* Save Icon */}
                                Save
                                {saveImageLoading && (
        <div className="apple-loading-spinner" style={{ marginLeft: '10px' }}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )}
                            </button>
                        </div>
                    </Modal>
                ) : (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => { setModalIsOpen(false); }}
                        contentLabel="Upload a New Avatar"
                        className="modal-content"
                        overlayClassName="modal-overlay"
                    >
                        <DraggableImage
                            src={imagePreview ? imagePreview : profileOwner.avatarUrl}
                            imagePos={imagePos}
                            setImagePos={setImagePos}
                            initialImagePos={initialImagePos}
                            setInitialImagePos={setInitialImagePos}
                        />
                    </Modal>
                )}
            </>
        </div>
    );
}

export default ProfilePage;