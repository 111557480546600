import React, { useState, useRef, useEffect } from 'react';

const DraggableImage = ({ src, imagePos, setImagePos, initialImagePos, setInitialImagePos, onClick }) => {
    const [dragging, setDragging] = useState(false);
    const [prevImagePos, setprevImagePos] = useState({ x: 0, y: 0 });
    const [prevMousePos, setPrevMousePos] = useState({ x: 0, y: 0 });
    const containerRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        // const savedImagePos = localStorage.getItem('imagePos');
        // const savedInitialImagePos = localStorage.getItem('initialImagePos');
        // if (savedImagePos && savedInitialImagePos) {
        //     setImagePos(JSON.parse(savedImagePos));
        //     setInitialImagePos(JSON.parse(savedInitialImagePos));
        // }   
    }, []);

    const handleMouseDown = (e) => {
        // if (imageRef.current) {
        //     const rect = imageRef.current.getBoundingClientRect();
        //     if (initialImagePos.x === 0 && initialImagePos.y === 0) {
        //         setInitialImagePos({
        //             x: rect.left,
        //             y: rect.top,
        //         });
        //     }
        //     setprevImagePos({
        //         x: rect.left,
        //         y: rect.top,
        //     });
        //     setPrevMousePos({
        //         x: e.clientX,
        //         y: e.clientY,
        //     });
        //     console.log("rect.left", rect.left, rect.top);
        // }
        // setDragging(true);
        // e.preventDefault();
    };
    const handleMouseMove = (e) => {
    // if (dragging && imageRef.current && containerRef.current) {
    //     const containerRect = containerRef.current.getBoundingClientRect();
    //     const rect = imageRef.current.getBoundingClientRect();

    //     const xDiff = prevImagePos.x - (prevMousePos.x - e.clientX) / 2;
    //     const yDiff = prevImagePos.y - (prevMousePos.y - e.clientY) / 2;
    //     const newPos = {
    //         x: xDiff - initialImagePos.x,
    //         y: yDiff - initialImagePos.y,
    //     };

    //     const lowerBound = containerRect.height - rect.height;
    //     const rightBound = containerRect.width - rect.width;

    //     if (newPos.x > 0) newPos.x = 0;
    //     if (newPos.y > 0) newPos.y = 0;
    //     if (newPos.x < rightBound) newPos.x = rightBound; 
    //     if (newPos.y < lowerBound) newPos.y = lowerBound; 

    //     setImagePos(newPos);
    // }
};

    const handleMouseUp = () => {
        // setDragging(false);
    };


    return (
        <div
            className="avatar-preview"
            ref={containerRef}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <img
                ref={imageRef}
                src={src}
                alt="Draggable"
                style={{ left: `${imagePos.x}px`, top: `${imagePos.y}px` }}
                onMouseDown={handleMouseDown}
                onClick={() => onClick()}

            />
        </div>
    );
};

export default DraggableImage;