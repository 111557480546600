import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';
import {endpoint} from './App';
function LoginPage() {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    // Clear local storage before login
    localStorage.clear();
    e.preventDefault();
    if (!credentials.username || !credentials.password) {
      setError('Please fill in all fields');
      return;
    }
    try {
      const response = await axios.post(`${endpoint}/auth/login`, credentials);
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user)); // Store user info as well
      setUser(user);
      navigate('/');
    } catch (error) {
      setError('Login failed: username or password incorrect');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Username</label>
            <input type="text" name="username" value={credentials.username} onChange={handleChange} />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input type="password" name="password" value={credentials.password} onChange={handleChange} />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" className="login-button">Sign In</button>
        </form>
        <div className="login-footer">
          <Link to="/forgot-password">Forgot password</Link>
          <Link to="/register">Sign up</Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;