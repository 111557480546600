import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';
import { endpoint } from './App';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [success, setSuccess] = useState("");
    
    const handleChange = (e) => {
        setEmail(e.target.value)
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
          setError('Please fill in your email');
          return;
        }
        try {
          const response = await axios.get(`${endpoint}/auth/send-reset-email?email=${email}`);
          setSuccess("t")
          setEmail('')
        } catch (error) {
          setError('An error occured');
          setSuccess("")
        }
      };

    return (
    <div className="login-container">
        <div className="login-card">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
            <div className="input-group">
            <label>Email</label>
            <input type="text" name="username" value={email} onChange={handleChange} />
            </div>
            {success && <p className='success'>Email Sent!</p>}
            {error && <p className="error">{error}</p>}
            <button type="submit" className="login-button">Send Link</button>
        </form>
        </div>
    </div>
    );
}

export default ForgotPassword;