import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import './Home.css'
import FilterPane from './FilterPane';
import PostList from './PostList';
import './PostForm.css'
import './PostDetail.css'
import { useAuth } from './AuthContext';
import {endpoint} from './App';

function Home() {
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [posts, setPosts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [postIdForDeletion, setPostIdForDeletion] = useState("");
  const [newPost, setNewPost] = useState({
    title: '',
    content: '',
    industryType: 'All',
    demand: 0,
  });
  const [editPost, setEditPost] = useState({
    id: '',
    title: '',
    content: '',
    industryType: '',
    demand: 0,
  });
  const initialPostState = {
    id: '',
    title: '',
    content: '',
    industryType: '',
    demand: 0,
  };
  const { user, setUser, loading, setLoading } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [sortOption, setSortOption] = useState("None"); // Add sortOption state


  useEffect(() => {
    if (localStorage.getItem('selectedIndustry') === null) {
      localStorage.setItem('selectedIndustry', 'All');
    }
    if (!loading) {
      const industry = localStorage.getItem('selectedIndustry');
      if (industry != null) {
        setSelectedIndustry(industry)
      }
      fetchPosts();
    } else {
      fetchPosts();
    }
  }, [selectedIndustry, sortOption]);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.get(`${endpoint}/posts/industryType/${localStorage.getItem('selectedIndustry')}`, {
        headers,
        params: { sortOption }
      });
      setPosts(response.data.content);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
    setLoading(false);
  };

  const deletePost = async (postId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      await axios.delete(`${endpoint}/posts/deletePost/${postId}`, {
        headers
      });
      fetchPosts();
      const userResponse = await axios.get(`${endpoint}/user`, { headers });
      setUser(userResponse.data);
    } catch (error) {
      console.error("Error Deleting posts:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (isEditing) {
      setEditPost({...editPost, [name]: value })
    } else {
      setNewPost({ ...newPost, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      if (isEditing) {
        const response = await axios.put(`${endpoint}/posts/editPost`, editPost, { headers });
        setShowForm(false);
        setIsEditing(false);
      } else {
        const response = await axios.post(`${endpoint}/posts`, newPost, { headers });
        localStorage.setItem('user',JSON.stringify(response.data))
        setUser(response.data)
        setShowForm(false);
      }
      fetchPosts();
    } catch (error) {
      console.error("Error submitting post:", error);
    }
    setEditPost(initialPostState);
    setNewPost(initialPostState);
  };

  const industries = ["All", "Medical", "Technology", "Education", "Finance", "Retail", "Hospitality", "Other"];
  const toggleVote = async (postId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const requestBody = {
        "postId": postId,
        "commentId": "",
        "isUpVote" : true
      }
      const response = await axios.put(`${endpoint}/posts/increaseDemand`, requestBody, {
        headers
      });
      localStorage.setItem('user',JSON.stringify(response.data.user))
      setUser(response.data.user)
    } catch (error) {
      console.error("Error toggling vote:", error);
    }
  };

  return (
    <div className='home-container'>
      <FilterPane 
        industries={industries} 
        selectedIndustry={selectedIndustry} 
        setSelectedIndustry={setSelectedIndustry} 
      />
      
      <PostList 
        posts={posts}
        setPosts={setPosts}
        sortOption={sortOption}
        setSortOption={setSortOption}
        setShowForm={setShowForm}
        setPostIdForDeletion={setPostIdForDeletion}
        setDeleteConfirmation={setDeleteConfirmation}
        setIsEditing={setIsEditing}
        setEditPost={setEditPost}
        showForm={showForm}
        isEditing={isEditing}
        editPost={editPost}
        newPost={newPost}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        showDeleteConfirmation={showDeleteConfirmation}
        deletePost={deletePost}
        toggleVote={toggleVote}
        industries={industries}
        postIdForDeletion={postIdForDeletion}
      />
    </div>
  );
}

export default Home;