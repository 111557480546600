import React from 'react';

const SortDropdown = ({ sortOption, setSortOption }) => {
  return (
    <div className="sort-dropdowns">
      <label htmlFor="sortOptions">Sort Options: </label>
      <select id="sortOptions" className='sortOption-box' onChange={(e) => setSortOption(e.target.value)} value={sortOption}>
        <option value="None">None</option>
        <option value="Demand">Demand</option>
      </select>
    </div>
  );
};

export default SortDropdown;
