import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css'; // Make sure to import the CSS file
import { Link, useLocation} from 'react-router-dom';
import {endpoint} from './App';
function PasswordReset() {
  const [credentials, setCredentials] = useState({ oldPassword: '', newPassword: '', confirmNewPassword: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');



  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.oldPassword || !credentials.newPassword || !credentials.confirmNewPassword) {
      setError('Please fill in all fields');
      return;
    }
    try {
        const response = await axios.post(`${endpoint}/password-reset?token=${token}`, {
            oldPassword: credentials.oldPassword,
            newPassword: credentials.newPassword
          });
      setSuccess("t")
      setError("")
    } catch (error) {
      setError('Reset failed');
      setSuccess('');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Old password</label>
            <input type="text" name="oldPassword" value={credentials.oldPassword} onChange={handleChange} />
          </div>
          <div className="input-group">
            <label>New password</label>
            <input type="password" name="newPassword" value={credentials.newPassword} onChange={handleChange} />
          </div>
          <div className="input-group">
            <label>Confirm new password</label>
            <input type="password" name="confirmNewPassword" value={credentials.confirmNewPassword} onChange={handleChange} />
          </div>
          {success && <p className='success'>Password reset successful</p>}
          {error && <p className="error">{error}</p>}
          <button type="submit" className="login-button">Reset</button>
        </form>
      </div>
    </div>
  );
}

export default PasswordReset;