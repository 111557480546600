import React from 'react';

const FilterPane = ({ selectedIndustry, setSelectedIndustry }) => {
    const industries = ['All', 'Medical', 'Technology', 'Education', 'Finance', 'Retail', 'Hospitality', 'Other'];

    return (
        <div className="filter-pane">
            <h3>Filter by Industry</h3>
            {industries.map(industry => (
                <div key={industry} className="filter-option">
                    <input
                        type="radio"
                        className='custom-radio'
                        id={industry}
                        value={industry}
                        checked={selectedIndustry === industry}
                        onChange={(e) => {
                            localStorage.setItem("selectedIndustry", e.target.value);
                            setSelectedIndustry(e.target.value);
                        }}
                    />
                    <label htmlFor={industry}>{industry}</label>
                </div>
            ))}
        </div>
    );
};

export default FilterPane;